<template>
  <div class="main-page">
    <header class="container pt-3 text-right">
      <router-link
        :to="{
          name: 'NumberInfoMobile',
          params: { msisdn: info.msisdn, categoryId: this.info.category.id },
        }"
        tag="button"
        class="btn close_btn shadow-none"
      ></router-link>
    </header>
    <main class="main-page__body-m pt-0 container">
      <div class="text-center">
        <img :src="classNumberImg" width="88" height="88" />
        <div class="change_m_class_name">{{ classNumberName }}</div>
        <div class="change_m_msisdn mt-4">{{ formattedMsisdn }}</div>
        <div class="reserve_m_total_sum mt-2">
          {{ $t("reserve.sum") }}<span>{{ info.total_sum }}</span>
          <span class="currency">с</span>
        </div>
        <div class="change_m_combination_help_text mt-4">
          {{ $t("reserve.comb") }}
        </div>
      </div>
      <div>
        <a
          class="change_m_combination d-block mt-4"
          :href="changeCombinationLink"
          >{{ changeCombination }}</a
        >
      </div>
      <!-- <div class="change_m_combination mt-4">{{ changeCombination }}#</div> -->
      <div class="change_m_balance_help_text mt-2">
        {{ $t("reserve.bal") }}
      </div>
      <div class="mt-3">
        <a
          class="btn btn-accept-filter btn-block"
          :href="changeCombinationLink"
          role="button"
          >{{ $t("reserve.btn_alt") }}</a
        >
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NumberReserveMobile",
  props: {
    info: Object,
  },
  computed: {
    ...mapGetters(["isMega24"]),
    classNumberName() {
      return this.$t(this.info.category.label_c);
    },
    classNumberImg() {
      return require(`@/assets/icons/${this.info.category.img_alt}`);
    },
    formattedMsisdn() {
      return this.info.msisdn
        .replace("996", "0")
        .replace(/(\d{4})(\d{3})(\d{3})/, "$1 $2 $3");
    },
    changeCombination() {
      let msisdn = this.info.msisdn.replace("996", "0");
      return `*250*2*${msisdn}#`;
    },
    changeCombinationLink() {
      let msisdn = this.info.msisdn.replace("996", "0");
      let prefix = "";
      if (this.isMega24 == "1") prefix = "#ob=";
      return `${prefix}tel:*250*2*${msisdn}%23`;
    },
  },
};
</script>

<style>
.reserve_m_total_sum {
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
}
.reserve_m_total_sum span {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}
.reserve_m_total_sum .currency {
  font-weight: 400;
}
</style>

